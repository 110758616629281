* {
  box-sizing: border-box
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato';
  color: #222222;
}

button {
  /* Because material-ui resets it to arial */
  font-family: 'Lato' !important;
}

/* React Router adds weird colors */
a:link{
  color: inherit !important;
}
a:visited{
  color:inherit !important;
}
a:hover{
  color:inherit !important;
}
a:focus{
  color:inherit !important;
}
a:active{
  color:inherit !important;
}


/*Font Stylings*/

.title {
  font-size: 1.5rem !important;
}

.subtitle {
  font-size: 1.25rem !important;
}

.body {
  font-size: 1rem !important;
}

.caption {
  font-size: 0.8125rem !important;
}